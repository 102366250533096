<template>
  <transition
    name="slide"
    appear>
    <slot />
  </transition>
</template>

<style scoped lang="scss">
.slide-transition {
  /* position: relative; */
}

.slide-leave-active {
  z-index: 1000;
  position: relative;
}

.slide-enter-active {
  z-index: 10;
  position: relative;
}

.slide-leave-active,
.slide-enter-active {
  transition: opacity 0.7s ease-out;

  /* opacity: 1; */
}

.slide-enter {
  opacity: 0;
}

.slide-leave {
  opacity: 1;
}

.slide-enter-to {
  opacity: 1;
}

.slide-leave-active,
.slide-leave-to {
  z-index: 1000;
  position: absolute;
  inset: 0;
}

.slide-leave-to {
  opacity: 0;
}
</style>
